<template>
  <div>
    <portal to="subheader-left">
      <ul class="nav nav-light-primary nav-bold nav-pills py-2">
        <router-link
          custom
          :to="{ name: 'kunden-view', params: { kundenId: kundenId } }"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li class="nav-item">
            <a
              class="nav-link px-xs-3 px-lg-4"
              :class="{ 'menu-item-active': isActive, active: isExactActive }"
              :href="href"
              @click="navigate"
            >
              <span>Details</span>
            </a>
          </li>
        </router-link>
      </ul>
    </portal>
    <KundenViewDetailHeader :isInitialLoading="isInitialLoading" :kunde="kunde"></KundenViewDetailHeader>
    <router-view
      :isLoading="isLoading"
      :isInitialLoading="isInitialLoading"
      :kunde="kunde"
      :vorgaengeCount="vorgaengeCount"
      :vorgaenge="vorgaenge"
    ></router-view>
  </div>
</template>

<style lang="scss" scoped>
.tox-tinymce {
  height: 200px !important;
}
.more-btn {
  cursor: pointer;
}
</style>

<script>
import { GET_KUNDEN } from '@/core/kunden/stores/kunden.module';

import { GET_VORGAENGE } from '@/core/vorgaenge/stores/vorgaenge.module';

import { mapState } from 'vuex';

import EventBus from '@/core/common/plugins/event-bus.js';

import KundenViewDetailHeader from '@/components/kunden/kunden-view-detail-header.vue';

export default {
  name: 'KundenViewComponent',
  components: {
    KundenViewDetailHeader,
  },
  props: {
    kundenId: Number,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      kunde: state => state.kunden.kunden[0],
      isLoading: state => state.kunden.isLoading,
      isInitialLoading: state => state.kunden.isInitialLoading,
      vorgaenge: state => state.vorgaenge.vorgaenge,
      vorgaengeCount: state => state.vorgaenge.count,
    }),
  },
  watch: {
    kunde(newKunde) {
      this.$store.dispatch(GET_VORGAENGE, {
        kunde: { id: { in: newKunde.kunden.map(kunde => kunde.id) } },
      });
    },
  },
  beforeDestroy() {
    EventBus.$off('reload');
  },
  mounted() {
    this.$store.dispatch(GET_KUNDEN, this.kundenId);
    EventBus.$on('reload', () => {
      this.$store.dispatch(GET_KUNDEN, this.kundenId);
    });
  },
  methods: {},
};
</script>
